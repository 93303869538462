const set = value => {
  const stringified = JSON.stringify(value)
  localStorage.setItem('store', stringified)
}

const get = () => {
  const data = localStorage.getItem('store')
  return JSON.parse(data)
}

export { set, get }
