import * as types from '../types'
const initialState = {
  ads: [],
  search: { geo: null, category: null },
  img: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ADS:
      return { ...state, ads: action.payload }
    case types.CHANGE_SEARCH:
      return { ...state, search: action.payload }
    case types.ADD_IMGS:
      return { ...state, img: [...state.img, ...action.payload] }
    default:
      return state
  }
}

export default reducer
