import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import Routes from './views'
import { useDispatch } from 'react-redux'
import { fetchCategories } from './store/actions/categories'
import { fetchDict, fetchDictIds } from './store/actions/dict'
import { searchAds } from './store/actions/ads'
import { getDictID } from './services/dict'

const App = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    fetchCategories(dispatch)
    fetchDict(dispatch)
    fetchDictIds(dispatch)
  }, [])
  searchAds(
    { geo: { lat: 45.460130637921004, lng: -0.3515625 }, category: null },
    dispatch
  )
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  )
}

export default App
