import { useEffect, useState } from 'react'
import Card from '.'
import styles from './Card.module.css'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { imgUrl } from '../../services/img'
import { getTranslation, userToken } from '../../store/selectors'

const AdsCard = ({ ad }) => {
  const history = useHistory()
  const title = useSelector(getTranslation('IdCulture', ad.idculture))
  const unite = useSelector(getTranslation('IdUnite', ad.idunite))
  const token = useSelector(userToken)

  const [img, setImg] = useState('')

  useEffect(() => {
    imgUrl({
      idobj: ad.imgs[0].idobj,
      small: true,
      token,
    }).then(setImg)
  }, [])

  return (
    <Card onClick={() => history.push('product/' + ad.identobj)}>
      <div className={styles.adsContent}>
        <div className={styles.adsImg}>
          <img src={img} />
        </div>
        <div className={styles.subText}>
          <div>{title}</div>
          <div>{`${ad.quantity} ${unite}`}</div>
          <div>
            {`${new Intl.DateTimeFormat('fr').format(
              ad.from
            )}-${new Intl.DateTimeFormat('fr').format(ad.to)}`}
          </div>
        </div>
      </div>
    </Card>
  )
}

export default AdsCard
