import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getCategories, userToken } from '../../store/selectors'
import { searchAds } from '../../store/actions/ads'
import styles from './SearchBar.module.css'
import LocationPicker from '../LocationPicker'
import { useHistory } from 'react-router'

const SearchBar = () => {
  const dispatch = useDispatch()
  const categories = useSelector(getCategories)
  const token = useSelector(userToken)
  const history = useHistory()
  const { t } = useTranslation()
  const [category, setCategory] = useState('')
  const [geo, setGeo] = useState(null)
  const [showMap, setShowMap] = useState(false)

  const toggleMap = () => {
    setShowMap(!showMap)
  }

  const changeCategory = event => {
    setCategory(event.target.value)
    sendSearch({ geo, category: event.target.value, token })
  }
  const changeGeo = event => {
    setGeo(event)
    sendSearch({ geo: event, category, token })
    setShowMap(false)
  }

  const sendSearch = ({ geo, category, token }) => {
    searchAds({ geo, category, token }, dispatch)
  }

  return (
    <>
      <div className={styles.searchbar}>
        <div className={styles.categories}>
          <label htmlFor="pet-select">{t('searchbar.CATEGORY')}: </label>
          <select
            value={category}
            name="pets"
            id="pet-select"
            onChange={changeCategory}
          >
            <option value="" />
            {categories.map(cat => (
              <option key={cat.id} value={cat.id}>
                {cat.value}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.geo} onClick={() => toggleMap()}>
          {t(geo ? 'searchbar.geo.EDIT' : 'searchbar.geo.NEW')}
        </div>
      </div>
      {showMap && (
        <div>
          <LocationPicker onChange={changeGeo} locationValue={geo} />
        </div>
      )}
    </>
  )
}

export default SearchBar
