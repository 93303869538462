import * as types from '../types'

const initialState = {
  user: null,
  userInfo: null,
  categories: [],
  dictsIds: {},
  dicts: [],
}

const pushOrReplaceDict = dict => state => ({
  ...state,
  dicts: [...state.dicts.filter(d => d.id !== dict.id), dict],
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN: {
      const user = action.payload
      return { ...state, user }
    }
    case types.LOGOUT:
      return { ...state, user: null, token: null }
    case types.UPDATE_USER_INFO: {
      const userInfo = action.payload
      return { ...state, userInfo }
    }
    case types.FETCH_CATEGORIES: {
      const categories = action.payload
      return { ...state, categories }
    }
    case types.FETCH_DICT_IDS: {
      const dictsIds = action.payload
      return { ...state, dictsIds }
    }
    case types.FETCH_DICT: {
      const dict = action.payload
      return pushOrReplaceDict(dict)(state)
    }
    default:
      return state
  }
}

export default reducer
