export const userConnected = (state) => state.settings.user !== null
export const userToken = (state) => state.settings.user?.session_token

export const getCategories = (state) => {
  const idDict = state.settings.dictsIds.IDPRODUCTCATEGORY
  const dict = state.settings.dicts.find((d) => d.id === idDict)
  if (dict) {
    let trads = []
    if (dict[0].dataset)
      trads =
        dict[0].dataset.fields.FItems ?? dict[0].dataset.fields.FListHelper
    else trads = dict[0].fields.FItems ?? dict[0].fields.FListHelper
    return trads.map(({ fields }) => ({
      value: fields.lib,
      id: fields.idelement,
    }))
  }
  return []
}

export const getAds = (state) => state.ads.ads

export const getTranslation = (field, identobj) => (state) => {
  const idDict = state.settings.dictsIds[field]
  const dict = state.settings.dicts.find((d) => d.id === idDict)
  if (dict && dict[0]) {
    let trads = []
    if (dict[0].dataset)
      trads =
        dict[0].dataset.fields.FItems ?? dict[0].dataset.fields.FListHelper
    else trads = dict[0].fields.FListHelper

    const field = trads?.find((el) => el.fields.idelement === identobj)
    if (field) return field.fields.lib
  }
  return ''
}
