import { createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import settings from './reducers/settings'
import ads from './reducers/ads'
import * as cache from './cache'

const reducer = combineReducers({
  settings,
  ads,
})

const preloaded = cache.get() ?? {}
const enhancer = composeWithDevTools(applyMiddleware(thunk))

const store = createStore(reducer, preloaded, enhancer)

store.subscribe(() => cache.set(store.getState()))

export default store
