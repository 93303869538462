import faker, { date } from 'faker'
import { GATEWAY } from './url'
const URL = `${GATEWAY}/load_products.betadigitis`

const buildURL = ({
  latitude = '',
  longitude = '',
  idproductitem = '',
  idutilisateur = '',
  idproductcategory = '',
  distance = 50000,
  offset = 0,
  limit = 10,
  idversion = 2,
  orderby = 'desc',
  language = 'fr-fr',
}) =>
  `${URL}?${[
    `latitude=${latitude}`,
    `longitude=${longitude}`,
    `distance=${distance}`,
    `offset=${offset}`,
    `limit=${limit}`,
    `idversion=${idversion}`,
    `idproductcategory=${idproductcategory}`,
    `idproductitem=${idproductitem}`,
    `orderby=${orderby}`,
    `idutilisateur=${idutilisateur}`,
    `language=${language}`,
  ]
    .filter(str => !str.endsWith('='))
    .join('&')}`

const ad = () => ({
  id: faker.datatype.uuid(),
  title: faker.animal.type(),
  img: faker.image.imageUrl(),
  from: faker.date.past(),
  to: faker.date.future(),
  desc: faker.lorem.sentences(),
  unit: faker.hacker.noun() + 's',
  quantity: faker.datatype.number(10) * 10,
})

export const getAd = async ({ id, token }) => {
  const res = await fetch(
    buildURL({ idutilisateur: token, idproductitem: id })
  ).then(r => r.json())
  const toReturn = res.list.find(r => r.identobj === id)
  const img = toReturn.photoOperation.list.map(e => e.proxyobject).map(parseImg)
  const additionnalData = { identobjex: toReturn?.proxyobject.identobjex }
  return {
    img,
    ad: {
      ...additionnalData,
      ...toReturn?.proxyobject.childobject.proxyobject,
    },
  }
}

const parseAd = ({
  identobj,
  quantity,
  IdMoney,
  Price,
  IdPriceunit,
  description,
  datedisponibilite,
  IdCultureConcernee,
  dateenlevementfin,
  identobjex,
  idunite,
  idculture,
}) => ({
  id: identobj,
  identobj,
  IdCultureConcernee,
  from: new Date(datedisponibilite),
  to: new Date(dateenlevementfin),
  description,
  IdPriceunit,
  quantity,
  price: Price,
  IdMoney,
  identobjex,
  idunite,
  idculture,
})

const parseImg = ({
  Titre,
  description,
  height,
  width,
  identobj,
  identobjpar,
  idobj,
  nom,
  orientation,
}) => ({
  title: Titre,
  description,
  height,
  width,
  orientation,
  idobj,
  identobj,
  identobjpar,
  nom: nom.split('/').pop(),
})

export const getAds = async ({ geo, category, token }) => {
  console.log(geo, category, token)
  const res = await fetch(
    buildURL({
      latitude: geo?.lat,
      longitude: geo?.lng,
      idproductcategory: category,
      idutilisateur: token,
    })
  ).then(r => r.json())
  if (res.list) {
    console.log(res)
    const ads = res.list
      .map(a => ({
        imgs: a.photoOperation,
        ad: {
          ...a.proxyobject.childobject.proxyobject,
          identobjex: a.proxyobject.identobjex,
        },
      }))
      .map(({ ad, imgs }) => ({
        ...parseAd(ad),
        imgs: imgs.list.map(i => i.proxyobject).map(parseImg),
      }))
    console.log(ads)
    return ads
  }
  return []
}
