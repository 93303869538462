import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import styles from './Login.module.css'
import { login as fetchLogin } from '../../store/actions/auth'

const Login = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [login, setLogin] = useState()
  const [password, setPassword] = useState()
  const [error, setError] = useState()
  const onSubmit = ({ login, password }) => {
    fetchLogin({ email: login, password }, dispatch)
      .then((_) => history.goBack())
      .catch((err) => setError(err))
  }
  return (
    <div className={styles.container}>
      <form
        className={styles.form}
        onSubmit={(event) => {
          event.preventDefault()
          onSubmit({ login, password })
        }}
      >
        <div className={styles.actions}>
          <div>Login</div>
          <div
            className={styles.link}
            onClick={() => history.push('/register')}
          >
            Register
          </div>
        </div>
        {error && <div className={styles.error}>{error}</div>}
        <input
          className={styles.input}
          type="mail"
          onChange={(event) => setLogin(event.target.value)}
        />
        <input
          className={styles.input}
          type="password"
          onChange={(event) => setPassword(event.target.value)}
        />
        <input className={styles.button} type="submit" />
      </form>
    </div>
  )
}

export default Login
