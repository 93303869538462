import { DREAMFACTORY } from './url'

const buildURL = ({ idobj, height = 1000, width = 1000 }) =>
  `${DREAMFACTORY}/api/v2/betadigitis_shayofae/_table/beta_photos?fields=IDENTOBJPAR,IDENTOBJ,DATEREALISATION,longitude,latitude,FILEPATH,DELETED&filter=(IDENTOBJ='${idobj}')&include_count=true&width=${width}&height=${height}
`

export const imgUrl = async ({ idobj, token, small = false }) => {
  const url = small
    ? buildURL({ idobj, height: 300, width: 300 })
    : buildURL({ idobj })
  const res = await fetch(url, {
    headers: {
      'X-DreamFactory-API-Key':
        '36fda24fe5588fa4285ac6c6c2fdfbdb6b6bc9834699774c9bf777f706d05a88',
      'X-DreamFactory-Session-Token': token,
    },
  }).then(res => res.json())

  return 'data:image/png;base64,' + res.resource[0].bitmapdata
}
