import styles from './Layout.module.css'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { userConnected } from '../../store/selectors'
import { useHistory } from 'react-router'
import { logout } from '../../store/actions/auth'

const Layout = ({ children }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const connected = useSelector(userConnected)
  const dispatch = useDispatch()
  return (
    <div className={styles.layout}>
      <div>
        <header className={styles.headbar}>
          <div
            className={styles.headbarTitle}
            onClick={() => history.push('/')}
          >
            {' '}
            {t('headbar.TITLE')}
          </div>
          <div className={styles.headbarActions}>
            {connected ? (
              <>
                <div
                  className={styles.action}
                  onClick={() => history.push('/account')}
                >
                  {t('headbar.ACCOUNT')}
                </div>
                <div className={styles.action} onClick={() => logout(dispatch)}>
                  {t('headbar.LOGOUT')}
                </div>
              </>
            ) : (
              <>
                <div
                  className={styles.action}
                  onClick={() => history.push('/login')}
                >
                  {t('headbar.LOGIN')}
                </div>
                <div
                  className={styles.action}
                  onClick={() => history.push('/register')}
                >
                  {t('headbar.REGISTER')}
                </div>
              </>
            )}
          </div>
        </header>
        <main>{children}</main>
      </div>
      <footer className={styles.footer}>
        <div>©2020 — ShaYoFae - Betadigitis SAS</div>
        <div>
          <div>
            <a
              href={
                'https://shayofae.com/legalnotice/' +
                navigator.language.slice(0, 2)
              }
            >
              {t('footer.LEGAL')}
            </a>
          </div>
          <div>
            <a
              href={
                'https://shayofae.com/privatePolicy/' +
                navigator.language.slice(0, 2)
              }
            >
              {t('footer.PRIVATE')}
            </a>
          </div>
        </div>
      </footer>
    </div>
  )
}
export default Layout
