import React, { useState } from 'react'

import MapPicker from 'react-google-map-picker'

const DefaultLocation = { lat: 0, lng: 0 }
const DefaultZoom = 2.2

const LocationPicker = ({ onChange, locationValue = DefaultLocation }) => {
  const [defaultLocation, setDefaultLocation] = useState(locationValue)
  const [location, setLocation] = useState(defaultLocation)
  const [zoom, setZoom] = useState(DefaultZoom)

  const handleChangeLocation = (lat, lng) => {
    setLocation({ lat: lat, lng: lng })
    onChange({ lat, lng })
  }

  const handleChangeZoom = newZoom => {
    setZoom(newZoom)
  }

  const handleResetLocation = () => {
    setDefaultLocation({ ...DefaultLocation })
    setZoom(DefaultZoom)
  }

  return (
    <MapPicker
      defaultLocation={defaultLocation}
      zoom={zoom}
      style={{ height: '700px' }}
      onChangeLocation={handleChangeLocation}
      onChangeZoom={handleChangeZoom}
      apiKey="AIzaSyA5_aSpbkt1CZBTK0vMFJrYMNDbfJ6zLqg"
    />
  )
}

export default LocationPicker
