import AdsCard from '../Card/AdsCard'
import styles from './List.module.css'

const CardList = ({ values }) => {
  return (
    <div className={styles.list}>
      {values && values.map((value) => <AdsCard key={value.id} ad={value} />)}
    </div>
  )
}

export default CardList
