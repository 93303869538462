import { DREAMFACTORY } from './url'
const URL = `${DREAMFACTORY}/api/v2/user/session`
const duration = 3600 * 3600 * 10

export const login = ({ email, password }) =>
  fetch(URL, {
    method: 'POST',
    body: JSON.stringify({ email, password, duration }),
    headers: {
      'Content-Type': 'application/json',
      'X-DreamFactory-API-Key':
        'b4049be247140629f1618f275b9a2ab70f98773415c93beb06dcbaf398a28a19',
    },
  }).then(res => res.json())
