import { GATEWAY } from './url'

export const getDict = ({ language = 'fr-fr', iddictio = 91939674 }) =>
  fetch(
    GATEWAY +
      '/load_dictio.betadigitis?' +
      [
        'version=2',
        'iddictio=' + iddictio,
        'language=' + language,
        'crypted=false',
        'idculture=0',
        // 'json=true',
      ].join('&')
  ).then(res => res.json())

export const getDictID = () =>
  fetch(
    GATEWAY +
      '/load_class_schema.betadigitis?version=2&classname=TBeta_marketplace'
  ).then(r => r.json())
