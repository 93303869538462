import { getDictID, getDict } from '../../services/dict'
import * as types from '../types'

export const fetchDict = (dispatch) => async (id) => {
  const dict = await getDict({ iddictio: id, language: navigator.language })

  if (dict !== null && dict.length > 0) console.log(dict[0])
  dispatch({ type: types.FETCH_DICT, payload: { ...dict, id } })
}

export const fetchDictIds = async (dispatch) => {
  const dictsIds = await getDictID()
  const ids = Object.entries(dictsIds.properties)
    .filter(([_, value]) => value.fielddesc?.iddictionary)
    .map(([key, value]) => ({
      [key]: value.fielddesc?.iddictionary,
    }))
    .reduce((acc, val) => ({ ...acc, ...val }), {})
  dispatch({ payload: ids, type: types.FETCH_DICT_IDS })

  await Promise.all(Object.values(ids).map(fetchDict(dispatch)))
}
