import React, { useState } from 'react'
import { useHistory } from 'react-router'
import styles from './Login.module.css'

const Register = () => {
  const history = useHistory()
  const [login, setLogin] = useState()
  const [password, setPassword] = useState()
  const onSubmit = ({ login, password }) => {
    console.log(login, password)
  }
  return (
    <div className={styles.container}>
      <form
        className={styles.form}
        onSubmit={event => {
          event.preventDefault()
          onSubmit({ login, password })
        }}
      >
        <div className={styles.actions}>
          <div>Register</div>
          <div className={styles.link} onClick={() => history.push('/login')}>
            Login
          </div>
        </div>
        <input
          className={styles.input}
          onChange={event => setLogin(event.target.value)}
        />
        <input
          className={styles.input}
          type="password"
          onChange={event => setPassword(event.target.value)}
        />
        <input
          className={styles.input}
          type="password"
          onChange={event => setPassword(event.target.value)}
        />
        <input className={styles.button} type="submit" />
      </form>
    </div>
  )
}

export default Register
