import en from './assets/i18n/en'
import fr from './assets/i18n/fr'

const config = {
  resources: {
    en: {
      translation: en,
    },
    fr: {
      translation: fr,
    },
  },
  lng: navigator.language.substring(0, 2),
  fallbackLng: 'en',

  interpolation: {
    escapeValue: false,
  },
}
export default config
