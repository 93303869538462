import { useSelector } from 'react-redux'
import Layout from '../components/Layout'
import List from '../components/List'
import SearchBar from '../components/Searchbar'
import { getAds } from '../store/selectors'

const Homepage = () => {
  const ads = useSelector(getAds)
  return (
    <Layout>
      <div>
        <SearchBar />
        <List values={ads} />
      </div>
    </Layout>
  )
}

export default Homepage
