import * as types from '../types'
import { login as fetchLogin } from '../../services/auth'

export const logout = dispatch => {
  dispatch({ type: types.LOGOUT })
}
export const login = async (credentials, dispatch) => {
  const user = await fetchLogin(credentials)
  console.log(user)
  if (user.error) throw user.error.message
  else dispatch({ type: types.LOGIN, payload: user })
}
