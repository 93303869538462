import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Layout from '../../components/Layout'
import { getAd } from '../../services/ads'
import { imgUrl } from '../../services/img'
import styles from './Product.module.css'
import { getTranslation, userToken } from '../../store/selectors'
import faker from 'faker'

const Contact = ({ num }) => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)

  const toggle = () => setShow(s => !s)

  return show ? (
    <div onClick={toggle} style={{ cursor: 'pointer' }}>
      Whatsapp: {num}
    </div>
  ) : (
    <div onClick={toggle} style={{ cursor: 'pointer' }}>
      {t('product.CONTACT')}
    </div>
  )
}

const Product = () => {
  const { id } = useParams()
  const [ad, setAd] = useState()
  const [img, setImg] = useState('')
  const { t } = useTranslation()
  const unite = useSelector(getTranslation('IdUnite', ad?.idunite))
  const priceUnite = useSelector(getTranslation('IdPriceunit', ad?.IdPriceunit))
  const culture = useSelector(getTranslation('IdCulture', ad?.idculture))
  const cultureConcernee = useSelector(
    getTranslation('IdCultureConcernee', ad?.IdCultureConcernee)
  )
  const money = useSelector(getTranslation('IdMoney', ad?.IdMoney))
  const token = useSelector(userToken)

  const init = async () => {
    const data = await getAd(id)
    setAd(data.ad)

    console.log(data.img)
    const img = await imgUrl({
      idobj: data.img[0].idobj,
      token,
    })
    setImg(img)
  }
  useEffect(() => {
    init()
  }, [])

  return (
    <Layout>
      {ad && (
        <div className={styles.ad}>
          <div className={styles.img}>
            <img src={img} />
          </div>
          <div className={styles.title}>{culture}</div>
          <div className={styles.date}>
            {t('product.DISPO') +
              ': ' +
              new Intl.DateTimeFormat('fr').format(
                new Date(ad.datedisponibilite)
              ) +
              ' to ' +
              new Intl.DateTimeFormat('fr').format(
                new Date(ad.dateenlevementfin)
              )}
          </div>
          <div className={styles.desc}>{ad.description}</div>
          <div className={styles.quantity}>{ad.quantity + ' ' + unite}</div>
          <div className={styles.price}>{ad.Price + ' ' + money}</div>
          <div className={styles.contact}>
            <Contact num={ad.whatsappnumber} />
          </div>
        </div>
      )}
    </Layout>
  )
}

export default Product
