import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Location from './Location'
import HomePage from './HomePage'
import Account from './Account'
import Product from './Product'
import Login from './Login'
import Register from './Login/Register'

const Routes = () => (
  <Switch>
    <Route path="/" component={HomePage} exact />
    <Route path="/account" component={Account} />
    <Route path="/product/:id" component={Product} />
    <Route path="/login" component={Login} />
    <Route path="/register" component={Register} />
    <Route path="/register" component={Login} />
  </Switch>
)

export default Routes
